$(document).on('turbolinks:load', function () {

    if($("#user_area_notification").length != 0) {
        let area_noti = $("#user_area_notification").val()
        if(area_noti == ''){
            $('#user_area_notification').val('').trigger("change");
        }
    
        $("#user_area_notification").on("select2:select", function (e) {
            if (e.params.data.id == '') {
                $(this).val('').trigger("change");

            } else {
                var newArray = [];
                let newData = $.grep($('#user_area_notification').select2('data'), function (value) {
                    return value['id'] != '';
                });
                newData.forEach(function (data) {
                    newArray.push(+data.id);
                });
                $(this).val(newArray).trigger("change");
            }
        });
    }
})