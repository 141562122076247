$(document).on('turbolinks:load', function () {

    var sliderPrice, sliderLength;
    if ($(".slider-price-js").length > 0) {
        $(".slider-price-js").each(function () {
            var objFrom = $(this).closest('.form-group').find('.slider-price-input-from')
            $(this).ionRangeSlider({});
            if ($(this).attr('data-fee-type') == 'Share with me') {
                range_slider($(".slider-price-js"), 0, 50, '', '%', 5, objFrom.val() == '' ? 0 : objFrom.val(), objFrom, '% of the brokers commission will be my fees.')
            }
            else {
                range_slider($(this), 0, 20000, "AED ", '', 500, objFrom.val() == '' ? 0 : objFrom.val(), objFrom, ' will be my fees.')
            }
        });
    }
    $("input[name='lead[fee_type]']").change(function () {
        $(".slider-price-js").ionRangeSlider({});
        var objFrom = $(".slider-price-js").closest('.form-group').find('.slider-price-input-from')
        if ($("input[name='lead[fee_type]']:checked").val() == 'Share with me') {
            $('.fee-slider').removeClass('d-none')
            range_slider($(".slider-price-js"), 0, 50, '', '%', 5, 0, objFrom, '% of the brokers commission will be my fees.')
            // $('.fee-tooltip').attr('data-original-title', 'if you would like a percentage of the broker\'s total commission')

        }
        else if ($("input[name='lead[fee_type]']:checked").val() == 'Just tip me') {
            $('.fee-slider').removeClass('d-none')
            range_slider($(".slider-price-js"), 0, 20000, "AED ", '', 500, 0, objFrom, ' will be my fees.')
            document.documentElement.style.setProperty("--selection-left", "100%");
            $('.price-msg').addClass('d-none')
            // $('.fee-tooltip').attr('data-original-title', 'to receive a fixed amount as your fees')

        }
        else if($("input[name='lead[fee_type]']:checked").val() == 'Thank you') {
            $('.fee-slider').addClass('d-none')
        }
    });
    if ($(".broker-slider-price-js").length > 0) {
        $(".broker-slider-price-js").ionRangeSlider({});
        // var objFrom = $("#slider-price-input-from").closest('.form-group').find('.slider-price-input-from')
        var objFrom = $('.slider-price-input-from')
        range_slider($(".broker-slider-price-js"), 10, 50, '', '%', 5, $('.slider-price-input-from').val(), objFrom, '% price.')

    }
});

export function range_slider(input, min, max, prefix, postfix, step, from, objFrom, message='') {
    var msg = ''
    var sliderPrice = input.data("ionRangeSlider");
    sliderPrice.update(
        {
            skin: "big",
            min: min,
            max: max,
            step: step,
            from: from || 0,
            prefix: prefix,
            postfix: postfix,
            max_postfix: "",
            prettify_enabled: true,
            // prettify_separator: ",",
            // hide_min_max: true,
            onUpdate: function (data) {
                if (prefix == '') {
                    msg = data.from + message
                } else {
                    msg = "AED " + addCommas(data.from) + message
                }
                objFrom.val(data.from);
                $('.create_lead_tip').html(msg)
                $(".amount").html(data.from)
            },
            onStart: function (data) {
                if (prefix == '') {
                    msg = data.from + message
                } else {
                    msg = "AED " + addCommas(data.from) + message
                }
                objFrom.val(data.from);
                $('.create_lead_tip').html(msg)
            },
            onChange: function (data) {
                if (prefix == '') {
                    msg = data.from
                    if(data.from > 30){
                        document.documentElement.style.setProperty("--selection-left", (3000 / (data.from))+"%");
                        $('.irs-single')[0].style.setProperty('background-color', '#FF0000', 'important');
                        $('.price-msg').removeClass('d-none')
                    }else{
                        document.documentElement.style.setProperty("--selection-left", "100%");
                        $('.irs-single')[0].style.removeProperty('background-color');
                        $('.price-msg').addClass('d-none')
                    }

                } else {
                    msg = "AED " + addCommas(data.from) + message
                    if(data.from > parseInt($('#price_calculation').val())){
                        document.documentElement.style.setProperty("--selection-left", ((parseInt($('#price_calculation').val())/2) / (data.from/200))+"%");
                        $('.irs-single')[0].style.setProperty('background-color', '#FF0000', 'important');
                        $('.price-msg').removeClass('d-none')
                    }else{
                        document.documentElement.style.setProperty("--selection-left", "100%");
                        $('.irs-single')[0].style.removeProperty('background-color');
                        $('.price-msg').addClass('d-none')
                    }
                }
                // $('.irs-min').html("Ask Me")
                if(data.from == 0){
                    $('.irs-single').html("Ask Me")
                }
                objFrom.val(data.from);
                $('.create_lead_tip').html(msg)
                $(".amount").html(data.from)
            },
            onFinish: function (data) {
                if (prefix == '') {
                    msg = data.from + message
                } else {
                    msg = "AED " + addCommas(data.from) + message
                }
                objFrom.val(data.from);
                $('.create_lead_tip').html(msg)
                $(".amount").html(data.from)
            }
        }
    )
    $('.irs-single').html("Ask Me")
}

function addCommas(nStr) {
    nStr += '';
    var x = nStr.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}