import intlTelInpututility from "intl-tel-input/build/js/utils";
$(document).on('turbolinks:before-cache', function (e) {
    // if ($('select.select2-hidden-accessible').length != 0) {
    //     $("select.select2-hidden-accessible").select2('destroy');
    // }
    if($(".modal-backdrop").length != 0){
        $(".modal").hide()
        $(".modal-backdrop").remove()
        $("body").removeClass('modal-open')
    }
});
$(document).on("click", ".pagy-bootstrap-nav a", function () {
    $("html, body").animate({ scrollTop: $(".lead-list, .agent-list").offset().top - 200 }, "slow");
    return false
});

$(document).on('turbolinks:load', function () {
    $('.select2-container').remove()
    if(sessionStorage.getItem("website_time") == null && sessionStorage.getItem("show_exit_modal") == null){
        sessionStorage.setItem("website_time", new Date());
        sessionStorage.setItem("show_exit_modal", 'true');
    }
    $('.collapse-animation.show').each(function(){
        $(this).parent().find(".fa").removeClass("fa-plus").addClass("fa-minus");
    });
    $('.collapse-animation').on('shown.bs.collapse', function(){
        $(this).parent().find(".fa").removeClass("fa-plus").addClass("fa-minus");
    }).on('hidden.bs.collapse', function(){
        $(this).parent().find(".fa").removeClass("fa-minus").addClass("fa-plus");
    });
    $("#password-field").fadeIn(400);
    init_filepond_profile_pic();
    PhoneDisplay(document.querySelector("#user_phone_no"))
    PhoneDisplay(document.querySelector("#agent_request_phone_no"))
    PhoneDisplay(document.querySelector("#contact_phone_no"));

    if ($('.filter-custom-select2').length != 0) {
        $('.filter-custom-select2').select2({
            width: '100%',
            theme: 'bootstrap-5',
            multiple: true,
            placeholder: 'Select locations you cover',
            sorter: function(results) {
                var query = $('.select2-search__field').val().toLowerCase();
                return results.sort(function(a, b) {
                    return a.text.toLowerCase().indexOf(query) -
                        b.text.toLowerCase().indexOf(query);
                });
            }
        }).on('change.select2', function(e){
            setCount($(this))
        }).on('select2:open', function(e){
            $('.select2-container--open .select2-search__field').attr('placeholder', 'Select locations you cover');
            var uldiv = $(this).siblings('span.select2').find('ul')
            if($(".select2-results .selected-item").length == 0) {
                $(".select2-results").prepend('<span class="select2-container--bootstrap-5"><span class="select2-selection select2-selection--multiple"><ul class="selected-item select2-selection__rendered"></ul></span></span>')
            }else{
                $(".select2-results .selected-item").html('')
            }
            uldiv.find('li').each(function(){
                var clone= $(this).clone()
                clone.removeClass('d-none');
                if(!clone.hasClass('custom-count')){
                    $(".select2-results .selected-item").prepend(clone)
                }
            });
            // uldiv.find('li.custom-count').addClass('d-none')
        })
        $(document).on('click', '.selected-item .select2-selection__choice__remove', function (e) {
            $(".filter-custom-select2 option[value='"+$(this).next('span').html()+"']").prop('selected', false).trigger('change.select2').trigger('change');
            $(this).parent('li').remove()
        });

        function setCount(thisObj){
            var uldiv = thisObj.siblings('span.select2').find('ul')
            var count = uldiv.find('li:not(.custom-count):not(:first-child)').length;
            uldiv.find('li:not(:first-child)').addClass('d-none')
            if(uldiv.find('li.custom-count').length != 0){
                uldiv.find('li.custom-count').removeClass('d-none').html(count+" more")
            }else{
                if(count == 0) {
                    uldiv.find('li.custom-count').addClass('d-none')
                }else {
                    uldiv.append("<li class='select2-selection__choice custom-count'>" + count + " more</li>")
                }
            }
        }
    }

    if ($('.custome-select2').length != 0) {
        $('.custome-select2').select2({
            width: '100%',
            theme: 'bootstrap-5',
            sorter: function(results) {
                var query = $('.select2-search__field').val().toLowerCase();
                return results.sort(function(a, b) {
                    return a.text.toLowerCase().indexOf(query) -
                        b.text.toLowerCase().indexOf(query);
                });
            }
        }).on('select2:open', function(e){
            $('.select2-container--open .select2-search__field').attr('placeholder', 'Search');
        })
    }

    if ($('.custome-multi-select2').length != 0) {
        $('.custome-multi-select2').select2({
            width: '100%',
            multiple: true,theme: 'bootstrap-5',
            sorter: function(results) {
                var query = $('.select2-search__field').val().toLowerCase();
                return results.sort(function(a, b) {
                    return a.text.toLowerCase().indexOf(query) -
                        b.text.toLowerCase().indexOf(query);
                });
            }
        })
    }

    if ($('.custome-multi-select2-limit').length != 0) {
        $('.custome-multi-select2-limit').select2({
            width: '100%',
            multiple: true,theme: 'bootstrap-5',
            maximumSelectionLength: 5,
            placeholder: 'Select locations you cover',
            sorter: function(results) {
                var query = $('.select2-search__field').val().toLowerCase();
                return results.sort(function(a, b) {
                    return a.text.toLowerCase().indexOf(query) -
                        b.text.toLowerCase().indexOf(query);
                });
            }
        }).on('select2:open', function(e){
            $('.select2-container--open .select2-search__field').attr('placeholder', 'Select locations you cover');
        })
    }
    $(".toggle-password").click(function () {
        $(this).toggleClass("fa-eye fa-eye-slash");
        var input = $($(this).attr("toggle"));
        if (input.attr("type") == "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

});

export function commonSwel(ajax_url, title, htmlTextOne, type, btnText, cancelbtnText, icon, btnColor, container='') {
    var valid = true
    if (container) {
        valid = $(container).find('form').valid()
    }
    if (valid) {
        Swal.fire({
            title: title,
            customClass: {
                confirmButton: btnColor,
                cancelButton: 'btn btn-outline-primary mx-2 btn-sm px-5'
            },
            buttonsStyling: false,
            html: htmlTextOne,
            imageUrl: icon,
            showCancelButton: true,
            cancelButtonText: cancelbtnText,
            confirmButtonText: btnText
        }).then((result) => {
            if (result.isConfirmed) {
                if (container) {
                    $.ajax({
                        type: type,
                        url: ajax_url,
                        data: $(container).find('form').serialize()
                    });
                } else {
                    $.ajax({
                        type: type,
                        url: ajax_url
                    });
                }
            }
        })
    }
}

export function successImageSwel(ajax_url, title, htmlTextOne, icon) {
    Swal.fire({
        title: title,
        width: 500,
        padding: '0em',
        imageUrl: icon,
        buttonsStyling: false,
        showConfirmButton: false,
        customClass: {
            image: 'm-0'
        }
    }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
            window.location.href = ajax_url;
        }
    })
}
export function successSwel(ajax_url, title, htmlTextOne, icon) {
    Swal.fire({
        title: title,
        html: htmlTextOne,
        timer: 5000,
        width: '800px',
        imageWidth: 400,
        imageHeight: 300,
        imageUrl: icon,
        buttonsStyling: false,
        showConfirmButton: false,
    }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
            window.location.href = ajax_url;
        }
    })
}

export function copyClipboard(url){
    var temp = $("<input>");
    $("body").append(temp);
    temp.val(url).select();
    document.execCommand("copy");
    temp.remove();
    $('<div class="copy-notification">Copied</div>').prependTo('body').delay(2000).fadeOut(600, function() {
        $('.copy-notification').remove();
    });
}
export function init_filepond_single(){
    $(".filepond-single").each(function() {
        if ($(this).data("name")) {
            createPoster(
                this,
                $(this).data("name"),
                $(this).data("size"),
                $(this).data("type"),
                $(this).data("url")
            );
        } else {
            FilePond.create(this, {
                name: "filepond"
            });
        }
    });
}

function createPoster(input, name, size, type, url) {

    FilePond.create(input, {
        name: "filepond",
        imagePreviewHeight: 200,
        filePosterMaxHeight: 200,
        files: [{
            options: {
                file: {
                    name: name,
                    size: size,
                    type: type,
                },
                metadata: {
                    poster: url,
                },
            },
        }]
    });
}

export function init_filepond_profile_pic() {
    $(".filepond-profile-pic").each(function () {
        if ($(this).data("name")) {
            createprofilePoster(
                this,
                $(this).data("name"),
                $(this).data("size"),
                $(this).data("type"),
                $(this).data("url"),
                $(this).data("remove-url"),
                $(this).data("id")
            );
        } else {
            var label = $(this).data('label-icon') ? '<p style="font-size: 5rem">' +$(this).data('label-icon')+ '</p>' : '<i class="fas fa-image fa-5x" style="font-size: 5rem"></i>'
            FilePond.create(
                document.querySelector('.profile-image'),
                {
                    labelIdle: label,
                    imagePreviewHeight: 170,
                    imageCropAspectRatio: '1:1',
                    imageResizeTargetWidth: 200,
                    imageResizeTargetHeight: 200,
                    stylePanelLayout: 'compact circle',
                    styleLoadIndicatorPosition: 'center bottom',
                    styleButtonRemoveItemPosition: 'center bottom'
                }
            );
        }
    });
}

function createprofilePoster(input, name, size, type, url, delete_url, item_id) {
    FilePond.create(input, {
        name: "filepond",
        imagePreviewHeight: 170,
        imageCropAspectRatio: '1:1',
        imageResizeTargetWidth: 200,
        imageResizeTargetHeight: 200,
        stylePanelLayout: 'compact circle',
        styleLoadIndicatorPosition: 'center bottom',
        styleButtonRemoveItemPosition: 'center bottom',
        files: [{
            options: {
                file: {
                    name: name,
                    size: size,
                    type: type
                },
                metadata: {
                    poster: url
                }
            }
        }]
    });
    $(".filepond-profile-pic").on("FilePond:removefile", function (e) {
        var id = item_id;
        if (id) {
            $.ajax({
                url: delete_url,
                type: "POST",
                datatype: "Script",
                data: {id: id},
                success: function (result) {
                }
            });
        }
    });
}

export function PhoneDisplay(input) {
// Set Phone Number Country Code Validations
    var errorMsg = document.querySelector(".error-msg")

    if (input) {
        // here, the index maps to the error code returned from getValidationError - see readme
        var errorMap = ["Invalid number", "Invalid country code", "Too short", "Too long", "Invalid number"];

        // initialise plugin
        var iti = window.intlTelInput(input, {
            initialCountry: "ae",
            separateDialCode: true,
            hiddenInput: "phone_no",
            utilsScript: intlTelInpututility,
            formatOnDisplay: false
        });
        var reset = function () {
            input.classList.remove("error");
            input.closest('.form-group').getElementsByClassName('error-msg')[0].innerHTML = ""
        };


        // on blur: validate
        input.addEventListener('blur', function () {
            reset();
            if (input.value.trim()) {
                if (iti.isValidNumber()) {
                    $('.phone-no').closest("form").find('input[type="submit"]').attr("disabled", false);
                } else {
                    input.classList.add("error");
                    var errorCode = iti.getValidationError();
                    if (typeof errorMap[errorCode] !== "undefined") {
                        input.closest('.form-group').getElementsByClassName('error-msg')[0].innerHTML = errorMap[errorCode];
                        $('.phone-no').closest("form").find('input[type="submit"]').attr("disabled", true);
                    }
                }
            }
        });

        input.addEventListener("countrychange", function (e, countryData) {
        });
        // on keyup / change flag: reset
        input.addEventListener('change', reset);
        input.addEventListener('keyup', reset);
    }
}
