import consumer from "./consumer"

consumer.subscriptions.create("ImportsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    console.log("Connected to import channel!");
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Called when there's incoming data on the websocket for this channel

    if (data['status'] === "success") {
      $('.upload-status').html(data['content'] + "<br />");
     }else if (data['status'] === "complete") {
        $('.upload-status').html(data['content'] + "<br />");
        setTimeout(function(){
              window.location.href = window.location.href;
          },900)
    } else if (data['status'] === "error") {
      $('.upload-status').html(data['content']);
    }
  }
});
