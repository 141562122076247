var timer = '';
$(document).on('turbolinks:load', function () {
    if($("#search_status_all").length != 0){
        $("#search_status_all").change(function () {
            var is_checked = $(this).prop("checked")
            $('.status-filter').prop('checked', false)
             $('#status_all_mob, #search_status_all').prop('checked', is_checked);
            search_form()
        });
    }
    if($("#status_all_mob").length != 0){
        $("#status_all_mob").change(function () {
            var is_checked = $(this).prop("checked")
            $('.mob-status-filter, .status-filter').prop('checked', false)
            $('#status_all_mob, #search_status_all').prop('checked', is_checked);
            search_form()
        });
        $(document).on('change', '.mob-status-filter', function(){
            var is_checked = $(this).prop("checked")
            var mob_value = $(this).val()
            var lType =[]
            $(".status-filter").each(function(){
                if($(this).val() == mob_value){
                    $(this).prop("checked", is_checked)
                    $(".mob-status-filter:checked").each(function(){
                        lType.push($(this).val());
                    });
                }
            });
            $('#status_all_mob, #search_status_all').prop('checked', lType.length == 0);
            search_form()
        })
    }
    if($("#lead_type_all_mob").length != 0){
        $("#lead_type_all_mob").change(function () {
            var is_checked = $(this).prop("checked")
            $('.mob-lead-type, .lead_type').prop('checked', false)
            $('#all_lead_type, #lead_type_all_mob').prop('checked', is_checked);
            search_form()
        });
        $(document).on('change', '.mob-lead-type', function(){
            var is_checked = $(this).prop("checked")
            var mob_value = $(this).val()
            var lType =[]
            $(".lead_type").each(function(){
                lType.push($(this).val());
                if($(this).val() == mob_value){
                    $(this).prop("checked", is_checked)
                }
            });
            $('#all_lead_type, #lead_type_all_mob').prop('checked', lType.length == 0);
            search_form()
        })
    }

    if($("#all_lead_type").length != 0) {
        $("#all_lead_type").change(function () {
            var is_checked = $(this).prop("checked")
            $('.lead_type, .mob-lead-type').prop('checked', false)
            $('#all_lead_type, #lead_type_all_mob').prop('checked', is_checked);
            search_form()
        });
    }

    $('.user_lead_type').change(function () {
        $('#search_broker_lead_type').val($(this).val())
        search_form()
        if($(this).val() === "broker-listed"){
            $('.verified-block').addClass('d-none')
        }else{
            $('.verified-block').removeClass('d-none')
        }
    })

    $('.status-filter').change(function (){
        var is_checked = $(this).prop("checked")
        var mob_value = $(this).val()
        $(".mob-status-filter").each(function(){
            if($(this).val() == mob_value){
                var lType =[]
                $(".status-filter:checked").each(function(){
                    $('#search_status_all').prop('checked', false);
                    $('#search_status_all').next().removeClass('active');
                    lType.push($(this).val());
                });
                if(lType.length == 0){
                    $('#search_status_all').prop('checked', true);
                    $('#search_status_all').next().addClass('active');
                }else{
                    $('#search_status_all').prop('checked', false);
                    $('#search_status_all').next().removeClass('active');
                }
                $(this).prop("checked", is_checked)
            }
        });
    });
    $('#customSwitch-verified-user').change(function (){
        $('#customSwitch-user').prop("checked",  $(this).prop("checked"))
        search_form()
    })
    $('.lead_type').change(function (){
        var lType =[]
        $(".lead_type:checked").each(function(){
            lType.push($(this).val());

        });
        if(lType.length == 0){
            $('.price-block').removeClass('d-none')
            $('.annual-price-block').removeClass('d-none')
             $('#all_lead_type, #lead_type_all_mob').prop('checked', true);
//             $('#all_lead_type, #lead_type_all_mob').next().addClass('active');
        }else {
            $('#all_lead_type, #lead_type_all_mob').prop('checked', false);
//             $('#all_lead_type, #lead_type_all_mob').next().removeClass('active');
            if ((jQuery.inArray("Buy", lType) !== -1) || (jQuery.inArray("Sell", lType) !== -1)) {
                $('.price-block').removeClass('d-none')
            } else {
                $('.price-block').addClass('d-none')
            }
            if ((jQuery.inArray("Take on Rent", lType) !== -1) || (jQuery.inArray("Give on Rent", lType) !== -1)) {
                $('.annual-price-block').removeClass('d-none')
            } else {
                $('.annual-price-block').addClass('d-none')
            }
        }
        if((jQuery.inArray("Buy", lType) !== -1)){
            $('.mortage-block, .off_plan-block').removeClass('d-none')
        }else{
            $('.mortage-block, .off_plan-block').addClass('d-none')
        }
        var is_checked = $(this).prop("checked")
        var mob_value = $(this).val()
        $(".mob-lead-type").each(function(){
            if($(this).val() == mob_value){
                $(this).prop("checked", is_checked)
            }
        });
    })

    $(document).on('change', '.lead_type, .off_plan, .verified-lead, .user_lead_type, .location, .property_type, .price, .timeline, .mortgage, .beds, .baths, .lead-sort, .status-filter, .area', function () {
        search_form()
    });

    $(document).on('keyup', '.name_or_id , .name-or-company_name', function (e) {
        clearTimeout(timer);
        timer = setTimeout(function() {
            e.preventDefault()
            search_form()
        }, 1000);

    });

    set_min_max_dropdown($('#search_price_min'), $('#search_price_max'))
    set_min_max_dropdown($('#search_annual_price_min'), $('#search_annual_price_max'))
});


function search_form() {
    // $("body").addClass('white-overlay');
    $.ajax({
        url: $("#filter_form").attr('action'),
        type: 'GET',
        dataType: 'script',
        data: $("#filter_form").serializeArray(),
        success: function (result) {
        }
    });
}

function set_min_max_dropdown(minPrice, maxPrice){
minPrice.on('change', function(){

      maxPrice.children("option").each(function(){
        var opt = $(this),
            optVal = parseInt(opt.attr('value'));

        if ( minPrice.val() != '' && optVal <= minPrice.val() ) {
          opt.attr('disabled','disabled');
        } else {
          opt.removeAttr('disabled');
        }

      });

    });

    maxPrice.on('change', function(){

      minPrice.children("option").each(function(){
        var opt = $(this),
            optVal = parseInt(opt.attr('value'));

        if ( maxPrice.val() != '' && optVal != 0 && optVal >= maxPrice.val() ) {
          opt.attr('disabled','disabled');
        } else {
          opt.removeAttr('disabled');
        }

      });

    });
}