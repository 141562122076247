// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("jquery");
require("bootstrap");
require("popper.js");
require("jquery-validation");
require("../custom/common");
require("../custom/custom");
require("../theme/main");
require("../custom/common");
require("../custom/range_silder");
require("../custom/search");
require("../custom/password");
require("../custom/star_rating.js");
require("../custom/notification");
require('ion-rangeslider')
require('slick-carousel')
require('../custom/jquery.sticky')
require('../custom/jquery.mask')
require('../custom/number_counter')
import './pagy.js.erb'

import intlTelInput from "intl-tel-input";
window.intlTelInput = intlTelInput;

import Swal from 'sweetalert2'
window.Swal = Swal

import * as FilePond from "filepond";
window.FilePond = FilePond;

import FilePondPluginFileEncode from "filepond-plugin-file-encode";
window.FilePondPluginFileEncode = FilePondPluginFileEncode;

import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
window.FilePondPluginFileValidateType = FilePondPluginFileValidateType;

import FilePondPluginImagePreview from "filepond-plugin-image-preview";
window.FilePondPluginImagePreview = FilePondPluginImagePreview;

import FilePondPluginFilePoster from "filepond-plugin-file-poster";
window.FilePondPluginFilePoster = FilePondPluginFilePoster;

import FilePondPluginImageCrop from "filepond-plugin-image-crop";
window.FilePondPluginImageCrop = FilePondPluginImageCrop;


FilePond.registerPlugin(
    FilePondPluginFileEncode,
    FilePondPluginFileValidateType,
    FilePondPluginImagePreview,
    FilePondPluginFilePoster,
    FilePondPluginImageCrop
);

import 'select2'
global.$ = jQuery;
import  "../stylesheets/application.scss";

import {init_filepond_profile_pic, commonSwel, copyClipboard, PhoneDisplay, successSwel, successImageSwel} from '../custom/common'
window.init_filepond_profile_pic = init_filepond_profile_pic;
window.commonSwel = commonSwel;
window.successSwel = successSwel;
window.successImageSwel = successImageSwel;
window.copyClipboard = copyClipboard;

import initialize_signup_form from '../custom/kassi'
import initialize_leads_form from '../custom/lead_form'
window.initialize_signup_form = initialize_signup_form;
window.initialize_leads_form = initialize_leads_form;

import {range_slider} from '../custom/range_silder'
import {starRating} from '../custom/star_rating'
import '../custom/typewriter'
window.range_slider = range_slider;
window.PhoneDisplay = PhoneDisplay;
window.starRating = starRating;
