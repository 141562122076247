$(document).on('turbolinks:load', function () {
    $("input#password-field, input#admin_password").on("focus keyup", function () {
        var score = 0;
        var a = $(this).val();
        var desc = new Array();

        // strength desc
        desc[0] = "Too short";
        desc[1] = "Weak";
        desc[2] = "Good";
        desc[3] = "Strong";
        desc[4] = "Best";

    });

    $("input#password-field, input#admin_password").on("focus keyup", function () {
        var score = 0;
        var a = $(this).val();
        var desc = new Array();

        // strength desc
        desc[0] = "Too short";
        desc[1] = "Weak";
        desc[2] = "Good";
        desc[3] = "Strong";
        desc[4] = "Best";

        // password length
        if (a.length >= 8) {
            $("#length").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#length").removeClass("valid").addClass("invalid");
        }

        // at least 1 digit in password
        if (a.match(/\d/)) {
            $("#pnum").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#pnum").removeClass("valid").addClass("invalid");
        }

        // at least 1 capital letter in password
        if (a.match(/[A-Z]/)) {
            $("#capital").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#capital").removeClass("valid").addClass("invalid");
        }
        // at least 1 lower letter in password
        if (a.match(/[a-z]/)) {
            $("#small").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#small").removeClass("valid").addClass("invalid");
        }

        // at least 1 special character in password {
        if (a.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) {
            $("#spchar").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#spchar").removeClass("valid").addClass("invalid");
        }


        if (a.length > 0) {
            //show strength text
            $("#passwordDescription").text(desc[score]);
            // show indicator
            $("#passwordStrength").removeClass().addClass("strength" + score);
        } else {
            $("#passwordDescription").text("Password not entered");
            $("#passwordStrength").removeClass().addClass("strength" + score);
        }
    });

    $("input#password-field, input#admin_password").blur(function () {

    });
    $("input#password-field, input#admin_password").on("focus keyup", function () {
        var score = 0;
        var a = $(this).val();
        var desc = new Array();

        // strength desc
        desc[0] = "Too short";
        desc[1] = "Weak";
        desc[2] = "Weak";
        desc[3] = "Good";
        desc[4] = "Strong";
        desc[5] = "Best";

        $("#pwd_strength_wrap").fadeIn(400);

        // password length
        if (a.length >= 8) {
            $("#length").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#length").removeClass("valid").addClass("invalid");
        }

        // at least 1 digit in password
        if ((a.match(/\d/))&&(a.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/))) {
            $("#spchar-pnum").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#spchar-pnum").removeClass("valid").addClass("invalid");
        }

        // at least 1 capital letter in password
        if ((a.match(/[A-Z]/))&&(a.match(/[a-z]/))) {
            $("#capital-small").removeClass("invalid").addClass("valid");
            score++;
        } else {
            $("#capital-small").removeClass("valid").addClass("invalid");
        }

        if (a.length > 0) {
            //show strength text
            $("#passwordDescription").text(desc[score]);
            // show indicator
            $("#passwordStrength").removeClass().addClass("strength" + score);
        } else {
            $("#passwordDescription").text("Password not entered");
            $("#passwordStrength").removeClass().addClass("strength" + score);
        }
    });

    $("input#password-field, input#admin_password").blur(function () {
        $("#pwd_strength_wrap").fadeOut(400);
    });
});