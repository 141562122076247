function add_validator_methods() {
$.validator.addMethod("emailExt", function(value, element, param) {
    return value.match(/^[a-zA-Z0-9_\.%\+\-]+@[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,}$/);
},'Please enter a valid email address');

}
function initialize_signup_form(ele_id) {
    add_validator_methods();
    var errorImagePath = $('#agent-form').data('error-image-path');
    var errorMessage = '<img src="' + errorImagePath + '" alt="Error" style="width: 20px; border-radius:100%;" class="error-icon"> This field is required.';
    var password_validation = $('.admin-broker-user-form').length !=0 ? $('.admin-broker-user-form #password-field').val().length != 0 ? true : false : true
    var form_id = "#" + ele_id;
    $(form_id).validate({
        ignore: ":hidden",
        rules: {
            "user[phone_no]": {required: true, number: true},
            "agent_request[phone_no]": {required: true, number: true},
            "agent_request[email]": {required: true},
            "agent_request[terms_accepted]": {required: true},
            "agent_request[company_name]": {required: true},
            "agent_request[rera]": {required: true},
            "agent_request[area_cover]": {required: true},
            "agent_request[address]": {required: true},
            "agent_request[profile_pic]": {required: true},
            "contact_us_name": {required: true},
            "contact_us_email": {required: true},
            "contact_us_phone": {required: true, number: true},
            "contact_us_subject": {required: true},
            "contact_us_msg": {required: true},
            "user[full_name]": {required: true},
            "user[terms_services]": {required: true},
            "user[email]": {required: true, email: true, emailExt: true},
            "new_email": {required: true, email: true, emailExt: true},
            "user[password]": {required: function (el) {return password_validation}},
            "user[password_confirmation]": {required: function (el){ return password_validation}, equalTo : "#password-field"},
            "sent_email[message]": {required: true},
            "sent_email[name]": {required: true},
            "sent_email[email]": {required: true, email: true, emailExt: true},
            "sent_email[phone_no]": {required: true, number: true},
            "area[location_id]": {required: true},
            "area[name]": {required: true, unique: true},
            "area[city]": {required: true},
            "location[name]": {required: true},
            "company[name]": {required: true},
            "company[logo]": {required: true}
        },
        errorPlacement: function (error, element) {
            if (element.hasClass('phone-no')) {
                element.closest(".iti").parent().append(error);
            }
            else if(element.hasClass('filepond--browser')){
                if($('.user-profile-image').closest('div').find('label').length < 3 ){
                    $('.user-profile-image').closest('div').append(error);
                }
                if($('.filepond-container').closest('div').find('label').length < 3 ){
                    $('.filepond-container').closest('div').append(error);
                }
            }
            else {
                element.closest("div").append(error);
            }
        },
        messages: {
            'user[password_confirmation]': {
                equalTo: "The passwords you entered do not match"
            },
            'user[profile_pic]': {required: errorMessage},
            'user[rera]': {required: errorMessage},
            'user[area_notification][]': {required: errorMessage},
            'user[company_id]': {required: errorMessage},
            'user[company_name]': {required: errorMessage},
            'user[phone_no]': {required: errorMessage},
            'user[email]': {required: errorMessage},
            'user[platform]': {required: errorMessage},
            'user[terms_services]': {required: errorMessage}
        },
        onkeyup: false, //Only do validations when form focus changes to avoid exessive ASI calls
        submitHandler: function (form) {
            disable_and_submit(form_id, form, "false");
        }
    });
}

function disable_and_submit(form_id, form, ajax) {
    disable_submit_button(form_id);
    if($(form_id).data('remote') ==  true){
        $.ajax({
            type: $(form_id).attr('method'),
            url: $(form_id).attr('action'),
            dataType: 'script',
            data:  $(form_id).serialize()
        });
    }else{
        form.submit();
    }

}

function disable_submit_button(form_id) {
    $(form_id).find("button").attr('disabled', 'disabled');
    // $(form_id).find("button").text("please_wait");
}

export default initialize_signup_form;
