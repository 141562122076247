function initialize_leads_form(ele_id) {

    var form_id = "#" + ele_id;
    $(form_id).validate({
        ignore: ":hidden",
        rules: {
            "lead[lead_type]": {required: true},
            "lead[location_id]": {required: true},
            "lead[area_id]": {
                required: {
                    depends: function(element){
                        if ($('#need_advice').length === 1 && $('.lead-type-radio-btn:checked').val() == 'Buy') {
                            return !($(".advice-check").prop('checked'));
                        } else {
                            return true;
                        }
                    }
                }
            },
            "lead[property_type_id]": {required: true},
            "lead[price]": {required: true},
            "lead[mortgage]": {required: true},
            "lead[fee_type]": {required: true},
            "lead[baths]": {required: true},
            "lead[beds]": {required: true},
            "lead[timeline]": {required: true},
            "lead[agree_to_share_info]": {required: true},
            "lead[terms_services]": {required: true},
            "offer[agree_term]": {required: true},
            "offer[agree_pay]": {required: true},
            "offer[referer_agree_pay]": {required: true},
            "offer[referer_agree_term]": {required: true},
            "reason[cancel_reason]": {required: true},
            "report[reason]": {required: true}
        },
        errorPlacement: function (error, element) {
            if (element.closest('.btn-group').hasClass('btn-group-toggle')) {
                element.closest(".form-group").append(error);
            }
            else {
                element.closest("div").append(error);
            }
        },
        messages: {
            'reason[cancel_reason]': {
                required: "Kindly select a reason for report."
            },
            'report[reason]': {
                required: "Kindly select a reason for report."
            }
        },
        onkeyup: false, //Only do validations when form focus changes to avoid exessive ASI calls
        submitHandler: function (form) {
            disable_and_submit(form_id, form, "false");
        }
    });
}

function disable_and_submit(form_id, form, ajax) {
    disable_submit_button(form_id);
    if($(form_id).data('remote') ==  true){
         $.ajax({
            type: $(form_id).attr('method'),
            url: $(form_id).attr('action'),
            dataType: 'script',
            data:  $(form_id).serialize()
        });
    }else{
    form.submit();
    }
}

function disable_submit_button(form_id) {
    $(form_id).find("button").attr('disabled', 'disabled');
    $(form_id).find("button").text("please_wait");
}

export default initialize_leads_form;
