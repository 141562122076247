$(document).on('turbolinks:before-cache', function (e) {
    $('.banner-logo').slick('unslick');
    $('.agent-slider').slick('unslick');
});
$(document).on('turbolinks:load', function () {

    $('.lead-form-submit').click(function (e){
        if($(this).closest('form').valid()){
            e.preventDefault();
            var content = ''
            if($(this).closest('form').attr('action').search('referrals/leads') == 1){
                content = '<label class="mb-2 font-weight-semi-bold"><b>Almost Done...</b>By clicking, "Submit" you confirm you are NOT AN AGENT & the listing is not false, fake or misleading other agents.</label><p style="font-size: 1rem" class="mt-5 font-weight-semi-bold">Non compliant accounts will be <span class="text-danger font-weight-bold"> Permanently Terminated.</span> </p>'

            }else{
                content='<label class="mb-2 font-weight-semi-bold"><b>Almost Done!</b> By clicking, "Submit" you confirm the listing is not false, fake, phishing or misleading other agents.</label><p style="font-size: 1rem" class="mt-5 font-weight-semi-bold">Non compliant accounts will be' +
                    '<span class="text-danger font-weight-bold"> <b>Permanently Terminated.</b></span></p>'
            }

            Swal.fire({
                title: '',
                customClass: {
                    confirmButton: 'btn btn-success mx-2 btn-sm px-5',
                    cancelButton: 'btn btn-primary mx-2 btn-sm px-5'
                },
                buttonsStyling: false,
                html: content,
                imageUrl: $(this).attr('data-image'),
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                confirmButtonText: 'Submit'
            }).then((result) => {
                if (result.isConfirmed) {
                    $(this).closest('form').submit()
                }
            })
        }
    })
    $('[data-toggle="tooltip"]').tooltip()

    if ($("#agent-form").length != 0) {
        initialize_signup_form("agent-form");
    }

    if ($("#new_user").length != 0) {
        initialize_signup_form("new_user");
    }

    if ($("#edit_user").length != 0) {
        initialize_signup_form("edit_user");
    }

    if ($("#contact-form").length != 0) {
        initialize_signup_form("contact-form");
    }

    if ($("#lead-form").length != 0) {
        initialize_leads_form("lead-form");
    }
    if ($(".make-offer").length != 0) {
        $(".make-offer").each(function () {
            initialize_leads_form($(this).attr("id"));
        });
    }
    if ($("#lead_price").length != 0) {
        $("#lead_price option[data-type='annual_price']").attr("disabled", "disabled")
    }

    $('.banner-logo').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        infinite: true,
        arrows: true,
        dots: false,
        speed: 500,
        centerMode: true,
        autoplay: false
    })
    $('.agent-slider').slick({
        slidesToShow: 8,
        slidesToScroll: 1,
        adaptiveHeight: true,
        infinite: true,
        arrows: true,
        dots: false,
        speed: 500,
        padding: 40,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    padding: 40
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    padding: 40
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    padding: 40
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    arrows: true,
                    padding: 40
                }
            }
        ]
    })
    $('.partner-logo').slick({
        dots: false,
        infinite: false,
        speed: 500,
        arrows: true,
        autoplay: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        padding: 40,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                    arrows: true,
                    centerMode: false
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: true,
                    centerMode: false,
                    autoplay: true,
                    infinite: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    centerMode: false,
                    autoplay: true,
                    infinite: true,
                }
            }
        ]
    });

    $('.home-cover').on('click', function () {
        $(this).children().css({
            'z-index' : 1,
            'opacity': 1
        });
        $(this).children().trigger('play');
    });

    $(document).on("change", "#lead_location_id", function () {
        get_area($(this).val());
    });

    $(document).on("change", ".location-change", function () {
        location_wise_area($(this).val());
    });

    $(".profile-image").on("FilePond:addfile", function () {
        if ($('.filepond--browser').closest('input').prop('required') == false) {
            $('.user-profile-image').closest('div').find('label').last().addClass('d-none');
        }
    });

    $(document).on("submit", ".edit_notification", function () {
        if ($('input[name="user[interested][]"]:checked').val() === undefined) {
            $('#user_interested-error').removeClass('d-none')
            return false;
        } else {
            return true;
        }
    });

    $(document).on("change", ".interested_in", function () {
        if ($('input[name="user[interested][]"]:checked').val() != undefined) {
            $('#user_interested-error').addClass('d-none')
            $('.notification-submit').removeAttr('disabled');
        } else {
            $('#user_interested-error').removeClass('d-none')
            $('.notification-submit').attr('disabled', true);
        }
    });

    $(document).on('click', '.make-call-click', function (e) {
        e.preventDefault();
        var link = $(this).attr('href')
        $.ajax({
            url: '/agents/offers/' + $(this).attr('data-lead-id') + '/make_call_option',
            type: 'GET',
            dataType: 'script',
            data: {set_timeout: true},
            success: function (result) {
                window.location.assign(link);
            }
        });
    })

    $(document).on('change', '.notification-container .c-checkbox input[type=checkbox]', function (e) {
        if ($(this).is(':checked')) {
            $(this).parent().find('img').removeClass('d-none')
        }else{
            $(this).parent().find('img').addClass('d-none')
        }
    })
    $(document).on('change', '.tfa-true', function (e) {
        $.ajax({
            type: "POST",
            dataType: "script",
            url: '/agents/leads/is_settings',
            data: {is_setting_modal_open: false},
            success: function (data) {
                $('#exampleModal').modal('hide');
            },
        });
    })
    $(document).on("change", ".lead-type-radio-btn", function () {
        price_calculation()
        if ($(this).val() == 'Buy') {
            $('.mortage_container, .advice-box, .off_plan').removeClass('d-none')
            $('.price-label').html("Budget (AED)")
            $('.end-user-price-field').attr("placeholder", "Budget (AED)")
        } else {
            $('.mortage_container, .advice-box, .off_plan').addClass('d-none')
        }
        // $('#lead_price').removeAttr('selected');
        $("#lead_price").val('').trigger('change')


        if ($(this).val() == 'Buy' || $(this).val() == 'Sell') {
            $("#lead_price option[data-type='annual_price']").attr("disabled", "disabled")
            $("#lead_price option[data-type='price']").removeAttr("disabled")
            if ($(this).val() == 'Sell') {
                $('.price-label').html("Price (AED)")
                $('.end-user-price-field').attr("placeholder", "Price (AED)")
            }
            if ($(this).val() == 'Buy') {
                $('#lead_price option:contains("ASK for Price")').attr("disabled", "disabled");
            }
        } else {
            $("#lead_price option[data-type='annual_price']").removeAttr("disabled")
            $("#lead_price option[data-type='price']").attr("disabled", "disabled")
            // $('.price-label').html("Annual Rent")
        }
        if ($(this).val() == 'Take on Rent') {
            $('.price-label').html("Annual Rent Budget (AED)")
            $('.end-user-price-field').attr("placeholder", "Annual Rent Budget (AED)")
        }
        if ($(this).val() == 'Give on Rent') {
            $('.price-label').html("Annual Rent Expected (AED)")
            $('.end-user-price-field').attr("placeholder", "Annual Rent Expected (AED)")
        }
    });


    $("nav .body-fix").on("click", function () {
        $("body").removeClass("body-fixed");
        setTimeout(function () {
            if ($("#mobNavBarCollapse.show").length) {
                $("body").addClass("body-fixed");
            }
        }, 500);
    });

    $('.show-more-filter').click(function (){
        var link = $(this);
        $(".filter-more-tab").slideToggle(500, function() {
            if ($(this).is(':visible')) {
                link.html('<p class="text-primary">Hide <i class="fa fa-chevron-up"></i></p>');
            } else {
                link.html('<p class="text-primary">Show More <i class="fa fa-chevron-down"></i></p>');
            }
        });
        $(this).toggleClass("open");
    })

    $(".show-filter").click(function () {
        $(".filters").toggleClass("open");
        $("body").toggleClass("filter-open");
    });

    $(".filter-close-btn").click(function () {
        $(".filters").removeClass("open");
        $("body").removeClass("filter-open");
    });

    $(".sorting").click(function () {
        $(".filters").removeClass("open");
        $("body").removeClass("filter-open");
    });
    $('#customSwitch-user, #customSwitch-alert-user').change(function () {
        if ($(this).is(":checked") == false) {
            $("." + $(this).attr('id') + "-off").addClass("text-dark").removeClass("text-muted")
            $("label[for=" + $(this).attr('id') + "]").removeClass("text-dark").addClass("text-muted")
            $('.register-now-btn').attr('href', '/agents/agent_requests')
            $('#password-switch').attr('href', '/users/password/new?e=findlead')
            $('#back-login').attr('href', '/users/sign_in?e=findlead')
        } else {
            $("." + $(this).attr('id') + "-off").removeClass("text-dark").addClass("text-muted")
            $("label[for=" + $(this).attr('id') + "]").addClass("text-dark").removeClass("text-muted")
            $('.register-now-btn').attr('href', '/users/sign_up')
            $('#password-switch').attr('href', '/users/password/new?e=referrer')
            $('#back-login').attr('href', '/users/sign_in?e=referrer')
        }
    });

    $(window).scroll(function () {
        var sticky = $('.web-filter'),
            scroll = $(window).scrollTop();

        if (scroll >= 700) {
            sticky.addClass('fixed');

        } else {
            sticky.removeClass('fixed');
            $(".filter-wrap").removeClass("main");
            $(".open-web-filter").removeClass("Active");
        }
    });

    $(".open-web-filter").click(function () {
        $(this).toggleClass("Active")
        $(".filter-wrap").toggleClass("main");
    });

    $('#price_format').mask("#,##0", {reverse: true});

    $('.price-calculation').change(function (){
        price_calculation()
    })

    $('.company').change(function () {
        if ($(this).val() == '') {
            $('.other-com').removeClass('d-none')
            $(this).attr('required', false)
        } else {
            $('.other-com').addClass('d-none')
            $(this).attr('required', true)
        }
    })
});

function price_calculation(){
    if($('.price-calculation').val() !== undefined) {
        var value = $('.price-calculation').val().replace(/,/g, ""),
            lead_type = $('.lead-type-radio-btn:checked').val()
        if ($.inArray(lead_type, ['Buy', 'Sell']) > -1) {
            $('#price_calculation').val(value * (0.6 / 100))
        } else {
            if ($.inArray(lead_type, ['Take on Rent', 'Give on Rent']) > -1) {
                $('#price_calculation').val(value * (2.5 / 100))
            } else {
                $('#price_calculation').val(value)
            }
        }
    }
}
$(document).on('turbolinks:load', function () {
    $('.select_propertty_type').on('change', function (e) {
        if ($.inArray($(e.currentTarget).find("option:selected").text(), ['Land', 'Commercial', 'Full building']) > -1) {
            $('.beds-baths').addClass('d-none')
        } else {
            $('.beds-baths').removeClass('d-none')
        }
    })

    var lead_type = $(".lead-type-radio-btn:checked").val()

    if (lead_type == 'Buy') {
        $('.mortage_container').removeClass('d-none')
        $('.price-label').html("Budget (AED)")
        $('.end-user-price-field').attr("placeholder", "Budget (AED)")
    } else {
        $('.mortage_container').addClass('d-none')
    }
    // $('#lead_price').removeAttr('selected');
    // $("#lead_price").val('').trigger('change')

    $("#body-field").on("keyup", function () {
        length = $(this).val().length;
        $("#body-count").html(length);
    });

    $(".broker-field").click(function () {
    var x = $("input:checked").length;
    $("#broker-count").html(x);
    if($(this).is(":checked")) {
        $(this).parent().parent().find('.broker-card-lead').css('border', '3px solid #4AA0DA')
    }else{
        $(this).parent().parent().find('.broker-card-lead').css('border', '3px solid lightgray')
    }
    });

    if (lead_type == 'Buy' || lead_type == 'Sell') {
        $("#lead_price option[data-type='annual_price']").attr("disabled", "disabled")
        $("#lead_price option[data-type='price']").removeAttr("disabled")
        if (lead_type == 'Sell') {
            $('.price-label').html("Price (AED)")
            $('.end-user-price-field').attr("placeholder", "Price (AED)")
        }
        if ($(this).val() == 'Buy') {
            $('#lead_price option:contains("ASK for Price")').attr("disabled", "disabled");
        }
    } else {
        $("#lead_price option[data-type='annual_price']").removeAttr("disabled")
        $("#lead_price option[data-type='price']").attr("disabled", "disabled")
        // $('.price-label').html("Annual Rent")
    }
    if (lead_type == 'Take on Rent') {
        $('.price-label').html("Annual Rent Budget (AED)")
        $('.end-user-price-field').attr("placeholder", "Annual Rent Budget (AED)")
    }
    if (lead_type == 'Give on Rent') {
        $('.end-user-price-field').attr("placeholder", "Annual Rent Expected (AED)")
    }
});

function get_area(area_id) {
    $.ajax({
        type: "POST",
        dataType: "script",
        url: '/referrals/leads/get_area',
        data: {id: area_id},
        success: function (data) {
        }
    });
}

function location_wise_area(l_id) {
    $.ajax({
        type: "POST",
        dataType: "script",
        url: '/search/get_area',
        data: {location: l_id},
        success: function (data) {
        }
    });
}










